import ConfirmModal from 'components/ConfirmModals/ConfirmModal';
import { useState } from 'react';

interface Props {
    closeModal: () => void;
    isDirty: boolean;
}

const useConfirmModal = ({ closeModal, isDirty }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        if (isDirty) return setIsOpen(true);
        closeModal();
    };

    const discardHandler = () => {
        setIsOpen(false);
        closeModal();
    };

    const Modal = () => {
        return (
            <ConfirmModal
                isOpened={isOpen}
                onClose={() => setIsOpen(false)}
                discardHandler={discardHandler}
            />
        );
    };

    return { ConfirmModal: Modal, handleClose };
};

export default useConfirmModal;
