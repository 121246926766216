import Loader from 'components/Loader';
import { CSSProperties, ReactElement } from 'react';
import { StyledButton } from './ColoredButton.styled';

interface Props {
    isLoading?: boolean;
    title: string;
    handler?: () => void;
    disabled?: boolean;
    padding?: string;
    style?: CSSProperties | undefined;
    color?: 'yellow' | 'blue' | 'transparent';
    loaderSize?: number;
    maxHeight?: string;
    Icon?: ReactElement;
}

const ColoredButton: React.FC<Props> = ({
    title,
    isLoading = false,
    handler = () => null,
    color = 'blue',
    disabled = false,
    padding = '16px 20px',
    style = undefined,
    maxHeight = '48px',
    loaderSize = 20,
    Icon,
}) => {
    return (
        <StyledButton
            style={style}
            disabled={isLoading || disabled}
            onClick={handler}
            $color={color}
            $padding={padding}
            $maxHeight={maxHeight}>
            {Icon}
            {isLoading ? (
                <Loader size={loaderSize} height="auto" color="inherit" />
            ) : (
                title
            )}
        </StyledButton>
    );
};

export default ColoredButton;
