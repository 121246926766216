import { AxiosError } from 'axios';
import { SERVER_ERROR } from 'constants/toast-messages';
import { toastError } from 'components/CustomToast/customToastFunctions';

interface Props {
    error: unknown;
    altMessage?: string;
}

const axiosServerError = ({ error, altMessage = SERVER_ERROR }: Props) => {
    if (error instanceof AxiosError) {
        const serverError = error?.response?.data[0];
        toastError(serverError || altMessage);
        return;
    }

    toastError(altMessage);
    return;
};

export default axiosServerError;
