import Dialog from '@mui/material/Dialog';
import {
    StyledDialogActions,
    StyledDialogContent,
    StyledDialogTitle,
} from './CreateNewOrganization.styled';
import CloseIcon from 'components/SVGIcons/CloseIcon';
import ColoredButton from 'components/Buttons/ColoredButton';
import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from 'shared/formElements/CustomInput';
import CustomSelectInput from 'shared/formElements/CustomSelectInput';
import CustomPhoneInput from 'shared/formElements/CustomPhoneInput';
import { Dispatch, SetStateAction } from 'react';
import AddCompanyLogo from './AddCompanyLogo';
import { useCompanyProfileOptions } from '@hooks/useCompanyProfileOptions';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import createNewOrganization from 'api/createNewOrganization';
import axiosServerError from '@helpers/axiosServerError';
import useConfirmModal from '@hooks/useConfirmModal';
import Blob from 'cross-blob';

interface Props {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    mutateList: () => void;
}

const schema = z.object({
    photo: z.instanceof(Blob).optional(),
    name: z.string().min(1, 'Required'),
    website: z.string().url('Please provide a valid url').min(1, 'Required'),
    phoneNumber: z.string().min(1, 'Required'),
    email: z.string().email().min(1, 'Required'),
    industry: z.string().min(1, 'Required').or(z.number().min(1, 'Required')),
    size: z.string().or(z.number()),
    typeOfOrganization: z.string().or(z.number()),
});

type FormType = z.infer<typeof schema>;

const CreateNewOrganization: React.FC<Props> = ({
    isOpen,
    setOpen,
    mutateList,
}) => {
    const { companyIndustry, companySize, companyType } =
        useCompanyProfileOptions();

    const methods = useForm<FormType>({
        defaultValues: {
            photo: undefined,
            name: '',
            website: '',
            phoneNumber: '',
            email: '',
            industry: '',
            size: '',
            typeOfOrganization: '',
        },
        resolver: zodResolver(schema),
    });

    const { handleSubmit, getValues, formState } = methods;

    const closeDialog = () => {
        setOpen(false);
        methods.reset();
    };

    const { ConfirmModal, handleClose } = useConfirmModal({
        closeModal: closeDialog,
        isDirty: formState.isDirty,
    });

    const createHandler = async () => {
        const {
            name,
            photo,
            website,
            phoneNumber,
            email,
            industry,
            size,
            typeOfOrganization,
        } = getValues();

        try {
            await createNewOrganization({
                title: name,
                photo: photo,
                website: website,
                phone_number: phoneNumber,
                email: email,
                industry_active: industry,
                company_size: size,
                type_of_company: typeOfOrganization,
            });
            mutateList();
            closeDialog();
        } catch (error) {
            axiosServerError({ error });
        }
    };

    return (
        <FormProvider {...methods}>
            <ConfirmModal />

            <Dialog
                open={isOpen}
                onClose={handleClose}
                scroll={'paper'}
                PaperProps={{ sx: { width: '620px', borderRadius: '20px' } }}>
                <StyledDialogTitle>
                    Add Organization
                    <CloseIcon
                        width="15px"
                        isCursorPointer
                        onClick={handleClose}
                    />
                </StyledDialogTitle>

                <StyledDialogContent dividers>
                    <AddCompanyLogo formKey="photo" />

                    <CustomInput
                        inputFormKey="name"
                        placeholder="Name"
                        borderRadius={4}
                    />

                    <CustomInput
                        inputFormKey="website"
                        placeholder="Website"
                        borderRadius={4}
                    />

                    <CustomPhoneInput formKey="phoneNumber" />

                    <CustomInput
                        inputFormKey="email"
                        placeholder="Organization email"
                        borderRadius={4}
                    />

                    <CustomSelectInput
                        formKey="industry"
                        placeholder="Organization industry"
                        itemsToSelect={companyIndustry}
                    />

                    <CustomSelectInput
                        formKey="size"
                        placeholder="Organization size"
                        itemsToSelect={companySize}
                    />

                    <CustomSelectInput
                        formKey="typeOfOrganization"
                        placeholder="Type of organization"
                        itemsToSelect={companyType}
                    />
                </StyledDialogContent>

                <StyledDialogActions>
                    <ColoredButton
                        handler={handleClose}
                        title="Cancel"
                        color="transparent"
                        disabled={formState.isSubmitting}
                    />

                    <ColoredButton
                        title="Add Organization"
                        handler={handleSubmit(createHandler)}
                        isLoading={formState.isSubmitting}
                    />
                </StyledDialogActions>
            </Dialog>
        </FormProvider>
    );
};

export default CreateNewOrganization;
