import { transientOptions } from '@helpers/transientOptions';
import { MenuItem, styled } from '@mui/material';

export const SelectButton = styled('div')<{ $isOpened: boolean }>(
    ({ theme: { colors, fonts }, $isOpened }) => ({
        ...fonts.body_1,
        color: colors.primaryColor1,
        padding: '8px 16px',
        display: 'flex',
        gap: '8px',
        cursor: 'pointer',
        width: '100%',
        alignItems: 'center',
        background: $isOpened ? colors.primaryColor_4 : 'transparent',

        '&:hover': {
            background: colors.primaryColor_4,
        },
    })
);

export const TruncatedText = styled('p')({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const PopoverBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 20px 0px rgba(17, 24, 33, 0.25)',
    borderRadius: '6px',
    width: '315px',
    position: 'relative',
});

export const CompanyImageWrapper = styled('div')({
    position: 'relative',
    width: '28px',
    height: '28px',
    flexShrink: 0,
});

export const StyledMenuItem = styled(
    MenuItem,
    transientOptions
)<{ $isSelected?: boolean }>(({ theme: { colors, fonts }, $isSelected }) => ({
    ...fonts.body_2,
    position: 'relative',
    color: $isSelected ? colors.primaryColor : colors.primaryText,
    background: $isSelected ? colors.primaryColor_4 : 'transparent',
    minHeight: '44px',
    gap: '8px',
    display: 'flex',
    alignItems: 'center',

    '& .MuiMenuItem-root': {
        padding: '0px',
    },

    '&:before': {
        content: "''",
        position: 'absolute',
        height: '100%',
        width: '5px',
        background: colors.primaryColor,
        display: $isSelected ? 'auto' : 'none',
        left: '0',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

export const CreateNewOrganizationButton = styled(MenuItem)(
    ({ theme: { colors, fonts } }) => ({
        ...fonts.body_2,
        color: colors.primaryText,
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        borderTop: `1px solid ${colors.secondaryColor_4}`,
    })
);
