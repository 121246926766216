import { FC, useState } from 'react';
import Accordion from 'components/Accordion';
import {
    Box,
    Button,
    useTheme,
    Drawer,
    ListItem,
    ListItemText,
    ListItemButton,
} from '@mui/material';
import {
    Wrapper,
    StyledList,
    StyledListItemText,
    StyledListItemButton,
    WrapperExpertsButton,
    Top,
    Bottom,
    LoginButton,
    SignUpButton,
    TitleListElement,
} from './CustomDrawer.styled';
import {
    ComponentInternalLink,
    HeaderProfileButton,
    ItemMenu,
    MenuItems,
    NavigationBar,
} from 'types/componentTypes';
import DecorativeLink from 'components/DecorativeLink';
import CustomLink from 'components/CustomLink';
import { useUserData } from 'state/userData';
import BurgerIcon from 'components/SVGIcons/BurgerIcon';
import useApiUrl from 'hooks/useApiUrl';
import { useGetUtmSessionTags } from '@hooks/useUTMtags';
import { theme } from '@theme/theme';
import getLoginUrl from '@helpers/getLoginUrlParams';

const { primaryText: primaryTextColor, primaryColor1: burgerIconColor } =
    theme.colors;

const CustomDrawer: React.FC<NavigationBar> = ({ leftSide, rightSide }) => {
    const [isOpened, setIsOpened] = useState(false);

    const theme = useTheme();
    const apiURL = useApiUrl();
    const isUserLoggedIn = useUserData((state) => state.isLoggedIn);
    const utmTags = useGetUtmSessionTags();

    const toggleDrawer = (open: boolean) => () => setIsOpened(open);

    const topLayout = {
        menu_items: (component: MenuItems) => {
            return (
                <Accordion
                    title={component.title}
                    $mainColor={primaryTextColor}
                    $fontStyles={theme.fonts.body_2}
                    $borderNone>
                    <Box
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}>
                        <StyledList>
                            {component.items.map((listElement) => {
                                const createVacancyTitle =
                                    listElement.title === 'Create vacancy';

                                if (createVacancyTitle)
                                    return (
                                        <TitleListElement key={listElement.id}>
                                            {listElement.title}
                                        </TitleListElement>
                                    );

                                return (
                                    <ListItem
                                        key={listElement.id}
                                        disablePadding>
                                        <StyledListItemButton>
                                            <CustomLink
                                                href={`${apiURL}${listElement.url}`}>
                                                <StyledListItemText
                                                    primary={listElement.title}
                                                />
                                            </CustomLink>
                                        </StyledListItemButton>
                                    </ListItem>
                                );
                            })}
                        </StyledList>
                    </Box>
                </Accordion>
            );
        },

        menu_item: ({ style, url, title }: ItemMenu) => {
            const loginUrl = getLoginUrl(url, title);

            switch (style) {
                case 'internal': {
                    return (
                        <CustomLink href={url}>
                            <ListItemButton>
                                <ListItemText primary={title} />
                            </ListItemButton>
                        </CustomLink>
                    );
                }
                case 'external': {
                    return (
                        <a href={loginUrl}>
                            <ListItemButton>
                                <ListItemText primary={title} />
                            </ListItemButton>
                        </a>
                    );
                }
            }
        },

        internal_link: (component: ComponentInternalLink) => {
            return (
                <WrapperExpertsButton>
                    <StyledListItemButton>
                        <DecorativeLink {...component} />
                    </StyledListItemButton>
                </WrapperExpertsButton>
            );
        },
    };

    const bottomLayout = {
        menu_item: ({ url, title, style }: ItemMenu) => {
            const Button = <LoginButton>{title}</LoginButton>;
            const urlConstructor = new URL(`${apiURL}${url}`);

            if (title === 'Login') {
                urlConstructor.search = new URLSearchParams(utmTags).toString();
            }

            switch (style) {
                case 'internal': {
                    return <CustomLink href={url}>{Button}</CustomLink>;
                }
                case 'external': {
                    return <a href={urlConstructor.href}>{Button}</a>;
                }
            }
        },

        action: ({ action: { url }, title, style }: HeaderProfileButton) => {
            const Button = <SignUpButton>{title}</SignUpButton>;

            switch (style) {
                case 'internal': {
                    return <CustomLink href={url}>{Button}</CustomLink>;
                }

                case 'external': {
                    return <a href={url}>{Button}</a>;
                }
            }
        },

        search_menu_items: () => null,
        extended_search_menu_item: () => null,
        profile_menu_items: () => null,
    };

    return (
        <>
            <Button onClick={toggleDrawer(true)} sx={{ padding: '0' }}>
                <BurgerIcon width={24} height={24} color={burgerIconColor} />
            </Button>

            <Drawer
                anchor={'left'}
                open={isOpened}
                onClose={toggleDrawer(false)}>
                <Wrapper>
                    <Top>
                        {leftSide.map((component, id) => {
                            const Component = topLayout[component.type] as FC;

                            if (!Component) return null;

                            return <Component key={id} {...component} />;
                        })}
                    </Top>

                    {!isUserLoggedIn && (
                        <Bottom>
                            {rightSide.map((component, id) => {
                                const type = component.type;

                                const Component = bottomLayout[type] as FC;

                                if (!Component) return null;

                                return <Component key={id} {...component} />;
                            })}
                        </Bottom>
                    )}
                </Wrapper>
            </Drawer>
        </>
    );
};

export default CustomDrawer;
