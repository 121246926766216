import { useDisplayType } from 'hooks/useDisplayType';
import { ConstructorProps } from '../Components/Constructor/Constructor';

export const globalProfessionalsFooterSchema = () => {
    const { isMobile } = useDisplayType();

    const schema: ConstructorProps[] = [
        {
            type: 'verticalSection',
            title: 'Contact',
            content: [
                {
                    type: 'text',
                    title: 'Singel 126',
                },
                {
                    type: 'text',
                    title: '1015AE',
                },
                {
                    type: 'text',
                    title: 'Amsterdam',
                },
                {
                    type: 'email',
                    title: 'info@globalprofessionals.com',
                    href: 'mailto:info@globalprofessionals.com',
                },
            ],
        },

        {
            type: 'verticalSection',
            title: 'Globalprofessionals',
            content: [
                {
                    type: 'link',
                    title: 'For professionals',
                    href: '/for-professionals',
                },
                {
                    type: 'link',
                    title: 'For clients',
                    href: '/for-clients',
                },
                {
                    type: 'link',
                    title: 'Search vacancies',
                    href: '/search-vacancies',
                },

                {
                    type: 'link',
                    title: 'Search professionals',
                    href: '/search-professionals',
                },
                {
                    type: 'link',
                    title: 'About us',
                    href: '/about-us',
                },
            ],
        },
        {
            type: 'verticalSection',
            title: 'Help & Support',
            content: [
                {
                    type: 'link',
                    title: 'Contact us',
                    href: '/contact-us',
                },
                {
                    type: 'link',
                    title: 'FAQ',
                    href: '/faq',
                },
                {
                    type: 'link',
                    title: 'Terms of service',
                    href: '/terms-of-service',
                },

                {
                    type: 'link',
                    title: 'Privacy policy',
                    href: '/privacy-policy',
                },
            ],
        },

        {
            type: isMobile ? 'mobileContent' : 'verticalSection',
            title: 'Social',

            content: [
                {
                    type: 'socialIcons',
                    content: [
                        {
                            href: `https://www.linkedin.com/company/glopros/`,
                            icon: '/img/linkedin_icon.svg',
                        },
                        {
                            href: '#',
                            icon: '/img/twitter_icon.svg',
                        },
                        {
                            href: '#',
                            icon: '/img/gitLab_icon.svg',
                        },
                    ],
                },
            ],
        },
    ];

    return schema;
};
