import { Modal } from '@mui/material';
import ColoredButton from 'components/Buttons/ColoredButton';
import CloseIcon from 'components/SVGIcons/CloseIcon';
import ModalWindowWrapper from 'shared/modals/ModalWindowWrapper.styled';
import { Buttons, Description, Title } from './ConfirmModal.styled';

interface Props {
    isOpened: boolean;
    onClose: () => void;
    discardHandler: () => void;
}

const ConfirmModal: React.FC<Props> = ({
    isOpened,
    onClose,
    discardHandler,
}) => {
    return (
        <Modal open={isOpened} onClose={onClose}>
            <ModalWindowWrapper>
                <CloseIcon
                    style={{ alignSelf: 'end' }}
                    onClick={onClose}
                    width={15}
                    isCursorPointer
                />

                <Title>
                    You have unsaved changes. Do you want to Continue?
                </Title>

                <Description>
                    There are unsaved changes. If you leave this tab, you`ll
                    lose your changes.
                </Description>

                <Buttons>
                    <ColoredButton
                        title="Discard Changes"
                        color="transparent"
                        handler={discardHandler}
                    />

                    <ColoredButton title="Continue editing" handler={onClose} />
                </Buttons>
            </ModalWindowWrapper>
        </Modal>
    );
};

export default ConfirmModal;
