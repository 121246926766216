export enum FeatureFlagKeys {
    invoicesTalent = 'invoice_timesheet_dashboard_talent',
    matchedProfessionalsToVacancyProfile = 'matched_professionals_to_vacancy_profile',
    brokerSettings = 'broker_settings',
    createNewOrganization = 'switch_between_organizations',
    likedVacancies = 'liked_vacancies',
    expertMeetingForm = 'expert_meeting_form',
    checklistForTalentSettingsNavigation = 'checklist-for-talent-settings-navigation',
    deleteAccountSettings = 'delete_account_settings',
    personalInformationSettings = 'personal_information_settings',
    emailAddressSettings = 'email_address_settings',
    phoneNumberSettings = 'phone_number_settings',
    changePasswordSettings = 'change_password_settings',
    secondAuthenticationFactor = 'second_authentication_factor',
    signUpDivided = 'sign_up_divided',
    signUpEmailVerification = 'sign_up_email_verification',
    hourlyRateSearchFilter = 'hourly_rate_search_filter',
}
