import {
    SearchField,
    ImgWrapper,
    StyledInput,
    ArrowUp,
    SearchContainer,
    ArrowDown,
    StyledMenu,
    CloseIco,
    Description,
    MenuSearchField,
    SearchFieldContent,
    Type,
} from './Search.styled';
import MenuItem from '@mui/material/MenuItem';
import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { useDisplayType } from 'hooks/useDisplayType';
import { SearchMenuItems } from 'types/componentTypes';
import useApiUrl from 'hooks/useApiUrl';
import Image from 'next/legacy/image';

const Search: React.FC<SearchMenuItems> = ({
    placeholder,
    closeHandler,
    items,
}) => {
    const [searchResults, setSearchResults] = useState({
        type: '',
        value: '',
        url: '',
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { isMobile } = useDisplayType();
    const open = Boolean(anchorEl);
    const apiURL = useApiUrl();

    const anchor = useRef<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (!searchResults.value && !searchResults.type) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItemHandler = ({ type, url }: { type: string; url: string }) => {
        setSearchResults({ ...searchResults, type: type, url: url });
        setAnchorEl(null);
    };

    const searchHandler = () => {
        if (searchResults.value != '') {
            console.log(
                `${apiURL}${searchResults.url}?value=${searchResults.value}`
            );
        }
    };

    return (
        <SearchContainer id="searchBox">
            <SearchField onClick={handleClick} ref={anchor}>
                <ImgWrapper onClick={searchHandler}>
                    <Image
                        src="/img/search_icon.svg"
                        width={14}
                        height={14}
                        alt="Social Media Icon"
                    />
                </ImgWrapper>

                <StyledInput
                    placeholder={
                        searchResults.type
                            ? `Search in ${searchResults.type} `
                            : placeholder
                    }
                    onChange={(e) =>
                        setSearchResults({
                            ...searchResults,
                            value: e.target.value,
                        })
                    }
                />

                {isMobile && closeHandler ? (
                    <Button
                        onClick={() => closeHandler(false)}
                        sx={{
                            padding: '0',
                        }}>
                        <CloseIco />
                    </Button>
                ) : open ? (
                    <ArrowUp style={{ cursor: 'pointer' }} />
                ) : (
                    <ArrowDown
                        onClick={() => setAnchorEl(anchor.current)}
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </SearchField>

            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'searchBox',
                }}>
                {items.map((searchOption) => {
                    return (
                        <MenuItem
                            key={searchOption.id}
                            onClick={() =>
                                menuItemHandler({
                                    type: searchOption.title,
                                    url: searchOption.url,
                                })
                            }
                            sx={{ margin: '12px 0px' }}>
                            <MenuSearchField>
                                <Image
                                    src={searchOption.icon}
                                    width={24}
                                    height={19}
                                />
                                <SearchFieldContent>
                                    <Type>{searchOption.title}</Type>
                                    <Description>
                                        {searchOption.description}
                                    </Description>
                                </SearchFieldContent>
                            </MenuSearchField>
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </SearchContainer>
    );
};

export default Search;
