import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';

interface StyledButtonProps {
    $color: 'yellow' | 'blue' | 'transparent';
    $padding: string;
    $maxHeight: string;
}

export const StyledButton = styled(
    'button',
    transientOptions
)<StyledButtonProps>(({ theme, disabled, $color, $padding, $maxHeight }) => ({
    ...theme.fonts.button_text,
    width: 'fit-content',
    blockSize: 'fit-content',
    padding: $padding,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: disabled
        ? theme.colors.secondaryColor_8
        : $color === 'blue'
        ? theme.colors.primaryColor
        : $color === 'yellow'
        ? theme.colors.secondaryColor_basic
        : 'none',

    border: `1px solid ${
        disabled
            ? theme.colors.secondaryColor_7
            : $color === 'blue'
            ? theme.colors.primaryColor
            : $color === 'yellow'
            ? theme.colors.secondaryColor_basic
            : theme.colors.primaryColor
    }`,

    borderRadius: '50px',
    color: disabled
        ? theme.colors.secondaryColor_7
        : $color === 'blue'
        ? theme.colors.white
        : $color === 'yellow'
        ? theme.colors.primaryText
        : theme.colors.primaryColor,
    flexGrow: '1',
    cursor: disabled ? 'not-allowed' : 'pointer',
    maxHeight: $maxHeight,
    minWidth: '100px',
}));
