import axios from 'axios';
import { NextApiRequest, NextApiResponse } from 'next';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';

export interface LocationPrediction {
    description: string;
    matched_substrings: {
        length: string;
        offset: string;
    }[];
    place_id: string;
    reference: string;
    structured_formatting: {
        main_text: string;
        main_text_matched_substrings: {
            length: number;
            offset: number;
        }[];
        secondary_text: string;
    };
    terms: {
        offset: number;
        value: string;
    }[];
    types: ['locality', 'political', 'geocode'];
}

export interface PredictionsResponse {
    predictions: LocationPrediction[];
    status:
        | 'OK'
        | 'ZERO_RESULTS'
        | 'INVALID_REQUEST'
        | 'OVER_QUERY_LIMIT'
        | 'REQUEST_DENIED'
        | 'UNKNOWN_ERROR';
}

type Types = '(cities)' | 'address' | 'postal_code';
const baseUrl = 'https://maps.googleapis.com/maps/api/place/autocomplete/json';

export const getLocationSuggestions = (
    value: string,
    type: Types = '(cities)'
) => {
    const { NEXT_PUBLIC_GOOGLE_LOCATIONS_KEY: key } = getCachedRuntimeConfig();

    const url = `/api/getLocationAutocomplete/?input=${value}&apiKey=${key}&type=${type}`;

    return axios.get<PredictionsResponse>(url);
};

const handler = async (req: NextApiRequest, res: NextApiResponse) => {
    const { input, type, apiKey } = req.query;

    const url = new URL(baseUrl);
    url.searchParams.append('input', input as string);
    url.searchParams.append('types', type as string);
    url.searchParams.append('fields', 'formatted_address');
    url.searchParams.append('key', apiKey as string);

    const stringUrl = url.toJSON();

    try {
        const { data } = await axios.get(stringUrl);
        res.status(200).json(data);
    } catch (_error) {
        res.status(500).json({
            error: 'Error fetching data from Google Places API',
        });
    }
};

export default handler;
