import { MouseEvent, ReactNode } from 'react';
import { Button } from './HeaderButton.styled';
import CustomLink from 'components/CustomLink';

interface Props {
    title: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    isOpened?: boolean;
    url?: string;
    children?: ReactNode;
}

const HeaderButton: React.FC<Props> = ({
    title,
    onClick,
    isOpened,
    children,
    url,
    ...rest
}) => {
    return (
        <CustomLink href={url ?? ''}>
            <Button
                onClick={onClick ?? undefined}
                isOpened={isOpened}
                {...rest}>
                {title} {children}
            </Button>
        </CustomLink>
    );
};

export default HeaderButton;
