import { ExtendedSearchMenuItem } from 'types/componentTypes';
import { useState } from 'react';
import {
    BrowseButton,
    CustomPopover,
    PopoverBody,
    InputsContainer,
    ButtonWrapper,
    SearchIconWrapper,
} from './ExtendedSearchMenu.styled';
import SearchIcon from 'components/SVGIcons/SearchIcon';
import { theme } from 'theme/theme';
import { FormProvider, useForm } from 'react-hook-form';
import { useDisplayType } from 'hooks/useDisplayType';
import { useRouter } from 'next/router';
import SearchInputs from './SearchInputs';
import { useUserData } from 'state/userData';
import SelectInput from './SelectInput';
import useUserType from 'hooks/useUserType';
import QueryString from 'qs';
import ProfileSearchIcon from 'components/SVGIcons/Header/VacancySearchIcon';
import VacancySearchIcon from 'components/SVGIcons/Header/VacancySearchIcon';
import CustomExpandIcon from 'components/SVGIcons/CustomExpandIcon';

const { primaryColor, secondaryColor_7, white } = theme.colors;

export interface InputsStateProps {
    job_title: string;
    mainLocationId: string;
    location: string;
    searchType: 'vacancies' | 'professionals';
}

const ExtendedSearchMenu: React.FC<ExtendedSearchMenuItem> = ({
    placeholder,
    inputs,
}) => {
    const router = useRouter();
    const isLoggedIn = useUserData((store) => store.isLoggedIn);
    const { isClient } = useUserType();
    const [searchAnchor, setSearchAnchor] = useState<null | HTMLElement>(null);

    const { isMobile } = useDisplayType();

    const isPopoverOpened = Boolean(searchAnchor);
    const methods = useForm<InputsStateProps>({
        defaultValues: {
            job_title: '',
            location: '',
            mainLocationId: '',
            searchType: isClient ? 'professionals' : 'vacancies',
        },
    });
    const { watch } = methods;

    const { location, job_title, searchType, mainLocationId } = watch();

    const isFormHasValues = Boolean(location || job_title);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSearchAnchor(event.currentTarget);
    };
    const handleClose = () => setSearchAnchor(null);

    const searchButtonHandler = () => {
        const defaultLocationData = { distance: 100, distance_unit: 'km' };

        const queryParams = {
            main_job_title: job_title ? [job_title] : [''],
            locations: [
                {
                    location: location,
                    id: mainLocationId,
                    ...defaultLocationData,
                },
                {
                    location: '',
                    id: '',
                    ...defaultLocationData,
                },
            ],
            locationInput: [location],
            type: searchType,
        };

        const stringifiedParams = QueryString.stringify(queryParams);

        if (isFormHasValues) {
            router.push({
                pathname: '/search',
                query: stringifiedParams,
            });
        }
    };

    const iconProps = { height: 24, width: 24 };

    const IconType = () => {
        switch (searchType) {
            case 'professionals': {
                return <VacancySearchIcon {...iconProps} />;
            }
            case 'vacancies': {
                return <ProfileSearchIcon {...iconProps} />;
            }
            default: {
                return <SearchIcon width={20} height={20} />;
            }
        }
    };

    return (
        <>
            {isMobile ? (
                <SearchIconWrapper onClick={handleClick}>
                    <SearchIcon
                        width={18}
                        height={18}
                        color={
                            isPopoverOpened ? primaryColor : secondaryColor_7
                        }
                    />
                </SearchIconWrapper>
            ) : (
                <BrowseButton onClick={handleClick} $isOpened={isPopoverOpened}>
                    <IconType />
                    {placeholder}
                    <CustomExpandIcon
                        sx={{
                            width: '8px',
                            transform: isPopoverOpened
                                ? 'rotate(180deg)'
                                : 'none',
                        }}
                    />
                </BrowseButton>
            )}

            <CustomPopover
                open={isPopoverOpened}
                anchorEl={searchAnchor}
                marginThreshold={0}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <PopoverBody>
                    <FormProvider {...methods}>
                        <InputsContainer $isLoggedIn={isLoggedIn}>
                            {inputs.map((input) => {
                                switch (input.type) {
                                    case 'select':
                                        return (
                                            <SelectInput
                                                key={input.id}
                                                {...input}
                                            />
                                        );

                                    case 'input':
                                        return (
                                            <SearchInputs
                                                key={input.id}
                                                searchHandler={
                                                    searchButtonHandler
                                                }
                                                input={input}
                                            />
                                        );

                                    default:
                                        return null;
                                }
                            })}

                            {!isMobile && (
                                <ButtonWrapper
                                    role="button"
                                    $isPointer={isFormHasValues}
                                    onClick={searchButtonHandler}>
                                    <SearchIcon
                                        width={20}
                                        height={20}
                                        color={white}
                                    />
                                </ButtonWrapper>
                            )}
                        </InputsContainer>
                    </FormProvider>
                </PopoverBody>
            </CustomPopover>
        </>
    );
};

export default ExtendedSearchMenu;
