import BasePage, { IBasePageProps, SeoProps } from './BasePage';
import React from 'react';
import { NextPage } from 'next/types';
import { ComponentWrapper } from './BasePage.styled';
import Header from 'components/Header';
import Footer from 'components/Footer';

type Props<P> = P & IBasePageProps;

export const basePageWrap = <P,>(
    Component: NextPage<Props<P>>,
    seo?: Partial<SeoProps>
) => {
    return (props: Props<P>) => {
        return (
            <BasePage {...props} seo={props.seo || seo} _class={Component.name}>
                <ComponentWrapper>
                    <Header />
                    <Component {...props} />
                    <Footer />
                </ComponentWrapper>
            </BasePage>
        );
    };
};

export default BasePage;
