import { useUserData } from 'state/userData';
import {
    CompanyImageWrapper,
    CreateNewOrganizationButton,
    PopoverBox,
    SelectButton,
    StyledMenuItem,
    TruncatedText,
} from './OrganizationSelect.styled';
import { useState } from 'react';
import { Popover } from '@mui/material';
import Image from 'next/legacy/image';
import useApiUrl from '@hooks/useApiUrl';
import ExpandMoreIcon from 'components/SVGIcons/ExpandMoreIcon';
import PlusIcon from 'components/SVGIcons/PlusIcon';
import { theme } from '@theme/theme';
import useSWR from 'swr';
import Loader from 'components/Loader';
import switchClientOrganization from 'api/switchClientOrganization';
import axiosServerError from '@helpers/axiosServerError';
import CreateNewOrganization from '../CreateNewOrganization/CreateNewOrganization';
import useUserType from '@hooks/useUserType';
import getClientOrganizations, {
    Organization,
} from 'api/getClientOrganizations';
import { mutate as globalMutate } from 'swr';

const OrganizationSelect = () => {
    const { isClient } = useUserType();
    const { user, setUserData } = useUserData();
    const apiURL = useApiUrl();

    const activeOrganization = user.activeCompany;
    const withCompanyLogo = !!activeOrganization?.photo?.path;

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const handleClick = (target: HTMLDivElement) => setAnchorEl(target);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);

    const [openNewOrganization, setOpenNewOrganization] = useState(false);

    const fetcher = async () => {
        const { data } = await getClientOrganizations();
        return data.results;
    };

    const {
        data: organizations,
        isLoading,
        mutate,
    } = useSWR('organizations', fetcher);

    const switchHandler = async (el: Organization) => {
        setAnchorEl(null);

        try {
            await switchClientOrganization({ slug: el.slug });
            setUserData({ activeCompany: el });
            globalMutate('/me/');
        } catch (error) {
            axiosServerError({ error });
        }
    };

    if (!isClient) return null;

    return (
        <>
            <SelectButton
                onClick={(e) => handleClick(e.currentTarget)}
                $isOpened={open}>
                {withCompanyLogo && (
                    <CompanyImageWrapper>
                        <Image
                            src={`${apiURL}${activeOrganization?.photo?.path}`}
                            width={28}
                            height={28}
                            layout="fill"
                            objectFit="contain"
                            alt="Company logo"
                        />
                    </CompanyImageWrapper>
                )}

                <TruncatedText>{activeOrganization?.title}</TruncatedText>

                <ExpandMoreIcon
                    width={12}
                    style={{ flexShrink: 0 }}
                    rotate={open}
                />
            </SelectButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <PopoverBox>
                    {isLoading && <Loader height="100px" size={20} />}

                    {organizations?.map((el) => {
                        const isSelected = el.slug === activeOrganization?.slug;

                        const Logo = () => {
                            const withLogo = el.photo?.path;

                            if (!withLogo) return null;

                            return (
                                <CompanyImageWrapper>
                                    <Image
                                        src={`${apiURL}${el.photo.path}`}
                                        width={28}
                                        height={28}
                                        layout="fill"
                                        objectFit="contain"
                                        alt="Company logo"
                                    />
                                </CompanyImageWrapper>
                            );
                        };

                        return (
                            <StyledMenuItem
                                key={el.slug}
                                $isSelected={isSelected}
                                onClick={() => {
                                    switchHandler(el);
                                }}>
                                <Logo />

                                {el.title}
                            </StyledMenuItem>
                        );
                    })}

                    <CreateNewOrganizationButton
                        onClick={() => {
                            setOpenNewOrganization(true);
                            setAnchorEl(null);
                        }}>
                        <PlusIcon
                            height={15}
                            color={theme.colors.secondaryText}
                        />
                        Create New Organization
                    </CreateNewOrganizationButton>
                </PopoverBox>
            </Popover>

            <CreateNewOrganization
                isOpen={openNewOrganization}
                setOpen={setOpenNewOrganization}
                mutateList={mutate}
            />
        </>
    );
};

export default OrganizationSelect;
