import { ReactNode, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputHelperText, StyledPhoneInput } from './CustomPhoneInput.styled';

interface Props {
    disabled?: boolean;
    formKey: string;
    onEnterKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    label?: string;
    isValid?: boolean;
    helperText?: string;
    wrapStyle?: { [key: string]: string };
}

const CustomPhoneInput: React.FC<Props> = ({
    disabled = false,
    formKey,
    onEnterKeyPress,
    label = '',
    isValid = true,
    helperText,
    wrapStyle = { width: '100%' },
}) => {
    const {
        control,
        formState: { isSubmitting, errors },
        trigger,
    } = useFormContext();
    const [isOnFocus, setIsOnFocus] = useState(false);

    const errorMessage = errors[formKey]?.message;

    const onBlurHandler = () => {
        setIsOnFocus(false);
        trigger(formKey);
    };

    const onFocusHandler = () => setIsOnFocus(true);

    return (
        <Controller
            name={formKey}
            control={control}
            render={({ field: { onChange, value } }) => (
                <div style={wrapStyle}>
                    <StyledPhoneInput
                        $withError={!!errorMessage}
                        $isOnFocus={isOnFocus}
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                            !!errorMessage && trigger(formKey);
                        }}
                        specialLabel={label}
                        dropdownStyle={{ height: '150px' }}
                        disabled={disabled || isSubmitting}
                        onEnterKeyPress={onEnterKeyPress}
                        isValid={isValid}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                    />

                    {(errorMessage || helperText) && (
                        <InputHelperText $withError={!!errorMessage}>
                            {
                                (errorMessage
                                    ? errorMessage
                                    : helperText) as ReactNode
                            }
                        </InputHelperText>
                    )}
                </div>
            )}
        />
    );
};

export default CustomPhoneInput;
