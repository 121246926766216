import Blob from 'cross-blob';
import { FileType } from 'polyfills';
import axiosInstance from './axiosConfig';

interface Props {
    photo?: FileType | Blob;
    title: string;
    website: string;
    phone_number: string;
    email: string;
    industry_active: number | string;
    company_size: number | string;
    type_of_company: number | string;
}

const createNewOrganization = (props: Props) => {
    const data = new FormData();
    props.photo && data.append('photo', props.photo);
    data.append('title', props.title);
    data.append('website', props.website);
    data.append('phone_number', props.phone_number);
    data.append('email', props.email);
    data.append('industry_active', String(props.industry_active));
    data.append('company_size', String(props.company_size));
    data.append('type_of_company', String(props.type_of_company));

    const headers = { 'Content-Type': 'multipart/form-data' };

    return axiosInstance.post(`/wt/api/v1/company/`, data, { headers });
};

export default createNewOrganization;
