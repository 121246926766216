import { mobileStyles } from 'types/displayTypeStyles';
import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { Popover } from '@mui/material';

export const BrowseButton = styled('button')<{ $isOpened: boolean }>(
    ({ theme, $isOpened }) => ({
        ...theme.fonts.body_1,
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50px',
        padding: '10px 10px',
        background: $isOpened ? theme.colors.primaryColor_4 : 'transparent',
        border: `1px solid ${theme.colors.secondaryColor_4}`,
        cursor: 'pointer',
        gap: '12px',
        flexShrink: 0,

        '&:hover': {
            background: theme.colors.primaryColor_4,
            border: `1px solid ${theme.colors.primaryColor}`,
            boxShadow: '0px 0px 5px 0px rgba(17, 24, 33, 0.30)',
        },
    })
);

export const CustomPopover = styled(Popover)(() => ({
    zIndex: '99',

    '& .MuiPaper-root': {
        boxShadow: '0px 4px 10px -5px rgba(0, 0, 0, 0.15)',
        width: '100%',
        maxWidth: 'none',
        height: '150px',
        borderRadius: ' 0px',
        padding: '35px 0px',

        [mobileStyles]: {
            height: 'auto',
            padding: '30px 0px',
        },
    },
}));

export const PopoverBody = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100vw',
}));

export const InputsContainer = styled(
    'div',
    transientOptions
)<{ $isLoggedIn: boolean }>(({ theme, $isLoggedIn }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '80px',
    width: '71%',
    maxWidth: '800px',
    border: `1px solid ${theme.colors.dividerColor}`,
    borderRadius: '100px 5px 90px 100px',
    background: $isLoggedIn ? theme.colors.secondaryColor_8 : 'transparent',
    gap: $isLoggedIn ? '40px' : '35px',
    overflow: 'hidden',
    justifyContent: 'space-between',
    paddingRight: '15px',

    [mobileStyles]: {
        flexDirection: 'column',
        borderRadius: '30px',
        alignItems: 'flex-start',
        gap: '0px',
        width: '95%',
        paddingRight: '0px',
        height: 'auto',
    },
}));

export const ButtonWrapper = styled(
    'button',
    transientOptions
)<{ $isPointer: boolean }>(({ theme, $isPointer }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: $isPointer
        ? theme.colors.primaryColor
        : theme.colors.dividerColor,
    borderRadius: '50px 5px 50px 50px',
    border: 'none',
    width: '45px',
    height: '45px',
    cursor: $isPointer ? 'pointer' : 'not-allowed',

    [mobileStyles]: {
        marginRight: '0px',
    },
}));

export const InputsBackground = styled('div')(({ theme }) => ({
    width: '100%',
    height: '150px',
    background: theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
}));

export const SearchIconWrapper = styled('button')(() => ({
    cursor: 'pointer',
    background: 'none',
    border: 'none',
}));
