import { useFormContext } from 'react-hook-form';
import { useState, ChangeEvent, useRef } from 'react';

const defaultRegEx =
    /(\.jpg|\.jpeg|\.doc|\.docx|\.png|\.html|\.rtf|\.txt|\.pdf)$/i;

interface Props {
    formKey: string;
    regex?: RegExp;
    uploadedFileNameKey?: string;
}

export const useFileUpload = ({
    formKey,
    regex = defaultRegEx,
    uploadedFileNameKey,
}: Props) => {
    const [file, setFile] = useState<File | string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [imagePreviewUrl, setPreviewUrl] = useState<string | null>();

    const { setValue, trigger, watch } = useFormContext();
    const uploadedFileName = uploadedFileNameKey
        ? watch(uploadedFileNameKey)
        : '';

    const inputRef = useRef<HTMLInputElement | null>(null);

    const browseFileHandler = () => {
        if (inputRef.current === null) return;
        inputRef.current.click();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (!file) return;

        if (!regex.exec(file.name)) {
            setFile(null);
            return setErrorMessage('Invalid file type');
        }

        if (file.type.startsWith('image/')) {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                const imageURL = reader.result;
                if (imageURL instanceof ArrayBuffer) return;
                setPreviewUrl(imageURL);
            };
        }

        setFile(file);
        setErrorMessage('');
        setValue(formKey, file, { shouldDirty: true });
        uploadedFileNameKey && setValue(uploadedFileNameKey, file.name);
        trigger(formKey);
    };

    const handleDeleteFile = () => {
        setFile(null);
        setValue(formKey, null, { shouldDirty: true });
        uploadedFileNameKey && setValue(uploadedFileNameKey, null);
    };

    return {
        file,
        errorMessage,
        browseFileHandler,
        handleChange,
        handleDeleteFile,
        inputRef,
        uploadedFileName,
        imagePreviewUrl,
    };
};
