import getCompanyOptions from 'api/getCompanyOptions';
import { useEffect, useState } from 'react';
import { useUserData } from 'state/userData';
import useSWR from 'swr';

interface Item {
    value: number;
    display_name: string;
}

interface StateTypes {
    companyIndustry: Item[];
    companySize: Item[];
    companyType: Item[];
}

export const useCompanyProfileOptions = () => {
    const {
        user: { companySlug },
    } = useUserData();

    const [options, setOptions] = useState<StateTypes>({
        companyIndustry: [],
        companySize: [],
        companyType: [],
    });

    const companyOptionsFetcher = async () => {
        if (!companySlug) return;

        const { data } = await getCompanyOptions(companySlug);

        const elements = data.actions.PUT;

        const modifiedOptions = {
            companyIndustry: elements.industry_active.choices,
            companySize: elements.company_size.choices,
            companyType: elements.type_of_company.choices,
        };

        setOptions(modifiedOptions);

        return modifiedOptions;
    };

    const { data: companyOptions } = useSWR(
        `/options/${companySlug}`,
        companyOptionsFetcher,
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        companyOptions && setOptions(companyOptions);
    }, [companyOptions]);

    return options;
};
