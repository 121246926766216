import { List, ListItemButton, ListItemText, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(() => ({
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100dvh',
}));

export const StyledList = styled(List)(() => ({
    padding: '0px',
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    ...theme.fonts.body_2,
    padding: '6px 25px',

    '& .selected': {
        backgroundColor: theme.colors.primaryColor_4,
    },

    '& .selected:hover': {
        backgroundColor: theme.colors.primaryColor_4,
    },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&& .MuiTouchRipple-child': {
        backgroundColor: theme.colors.primaryColor,
        borderRadius: '4px',
    },
}));

export const WrapperExpertsButton = styled('div')(() => ({
    marginTop: '40px',
}));

export const Top = styled('div')({
    overflowY: 'auto',
    padding: '50px 0px 100px 0px',
});

export const Bottom = styled('div')({
    width: '100%',
    height: '110px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
});

export const LoginButton = styled('button')(({ theme }) => ({
    ...theme.fonts.body_2,
    padding: '6px 17px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
}));

export const SignUpButton = styled('button')(({ theme }) => ({
    ...theme.fonts.body_2,
    padding: '6px 17px',
    cursor: 'pointer',
    backgroundColor: theme.colors.secondaryColor_basic,
    border: 'none',
    width: '108px',
    height: '36px',
    borderRadius: '50px',
}));

export const TitleListElement = styled('div')(() => ({
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 41px',
}));
