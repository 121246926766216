import Search from '../Search';
import MenuItem from '@mui/material/MenuItem';
import DropDown from 'components/DropDown';
import {
    HeaderBlock,
    ContentR,
    ContentL,
    LinkButton,
    TalkToExpertsWrapper,
    ActionButton,
    ImgWrapper,
    Logo,
    DisabledMenuItem,
} from './HeaderLG.styled';
import {
    ComponentHeader,
    MenuItems,
    ItemMenu,
    ComponentInternalLink,
    SearchMenuItems,
    HeaderProfileButton,
    ExtendedSearchMenuItem,
    ProfileMenuItems,
} from 'types/componentTypes';
import DecorativeLink from 'components/DecorativeLink';
import CustomLink from 'components/CustomLink';
import ExtendedSearchMenu from '../ExtendedSearchMenu';
import ProfileMenu from '../ProfileMenu';
import LogoIcon from 'components/SVGIcons/LogoIcon';
import { theme } from 'theme/theme';
import { useDisplayType } from 'hooks/useDisplayType';
import useApiUrl from 'hooks/useApiUrl';
import getLoginUrl from '@helpers/getLoginUrlParams';
import { useRouter } from 'next/router';

const featuresLinks = [
    //disabled links

    // talent urls
    'Matched to You',
    'Saved Vacancies',
    // 'Your Invitations',
    // 'Your applications',
    'Planned interviews',
    'Current contract',
    'Vacancies in process',
    // 'Expenses',

    // client urls
    // 'Your Professionals',
    // 'Saved Profiles',
    'Planned Interviews',
    'Transaction history',
];

const HeaderLG: React.FC<ComponentHeader> = ({
    navigationBar,
    logo,
    withBorder = true,
}) => {
    const apiUrl = useApiUrl();
    const logoColor = theme.colors.primaryColor;
    const { isTablet } = useDisplayType();
    const router = useRouter();
    const isSignUpPage = router.asPath.includes('sign-up');

    const layout = {
        menu_items: ({ title, items }: MenuItems) => {
            return (
                <DropDown title={title}>
                    {items.map(({ title, url, style }, id) => {
                        const disabledLink = featuresLinks.includes(title);
                        const MenuButton = <MenuItem>{title}</MenuItem>;

                        if (disabledLink) {
                            return (
                                <DisabledMenuItem key={id}>
                                    {title}
                                </DisabledMenuItem>
                            );
                        }

                        switch (style) {
                            case 'internal': {
                                return (
                                    <CustomLink key={id} href={url}>
                                        {MenuButton}
                                    </CustomLink>
                                );
                            }
                            case 'external': {
                                return (
                                    <a key={id} href={url}>
                                        {MenuButton}
                                    </a>
                                );
                            }
                        }
                    })}
                </DropDown>
            );
        },

        menu_item: ({ url, style, title }: ItemMenu) => {
            const Button = <LinkButton>{title}</LinkButton>;
            const isLogin = title === 'Login';

            const loginUrl = getLoginUrl(url, title);

            switch (style) {
                case 'internal': {
                    return <CustomLink href={url}>{Button}</CustomLink>;
                }
                case 'external': {
                    return <a href={isLogin ? loginUrl : url}>{Button}</a>;
                }
            }
        },

        internal_link: (component: ComponentInternalLink) => (
            <TalkToExpertsWrapper>
                <DecorativeLink {...component} />
            </TalkToExpertsWrapper>
        ),

        search_menu_items: (component: SearchMenuItems) => {
            return <Search {...component} />;
        },

        action: ({ style, action: { url }, title }: HeaderProfileButton) => {
            const Button = <ActionButton>{title}</ActionButton>;

            switch (style) {
                case 'internal': {
                    return <CustomLink href={url}>{Button}</CustomLink>;
                }
                case 'external': {
                    return <a href={url}>{Button}</a>;
                }
            }
        },

        extended_search_menu_item: (component: ExtendedSearchMenuItem) => {
            return <ExtendedSearchMenu {...component} />;
        },

        profile_menu_items: (component: ProfileMenuItems) => {
            return <ProfileMenu {...component} />;
        },
    };

    return (
        <HeaderBlock $withBorder={withBorder}>
            <ContentL>
                <CustomLink href="/">
                    <ImgWrapper>
                        {logo ? (
                            <Logo src={`${apiUrl}${logo}`} alt="Logo" />
                        ) : (
                            <LogoIcon
                                width={isTablet ? 125 : 153}
                                height={isTablet ? 27 : 33}
                                color={logoColor}
                            />
                        )}
                    </ImgWrapper>
                </CustomLink>

                {!isSignUpPage &&
                    navigationBar?.leftSide.map((component) => {
                        const Component = layout[component.type] as React.FC;

                        if (!Component) return null;

                        return <Component key={component.id} {...component} />;
                    })}
            </ContentL>

            <ContentR>
                {!isSignUpPage &&
                    navigationBar?.rightSide.map((component) => {
                        const Component = layout[component.type] as React.FC;

                        if (!Component) return null;

                        return <Component key={component.id} {...component} />;
                    })}
            </ContentR>
        </HeaderBlock>
    );
};

export default HeaderLG;
